<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container py-0">
          <v-form
            ref="salesOrderForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row v-if="item" id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="item.type"
                      :items="salesOrderTypes"
                      outlined
                      dense
                      hide-details="auto"
                      label="Order Type"
                      :rules="requiredRule"
                    >
                    </v-select>
                  </v-col>
                  <v-col v-if="!editMode" cols="12" md="6">
                    <v-autocomplete
                      v-model="item.customer"
                      :items="customers.items"
                      outlined
                      dense
                      item-text="storeName"
                      return-object
                      hide-details="auto"
                      label="Customer"
                      :rules="requiredRule"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col v-if="editMode" cols="12" md="6">
                    <v-autocomplete
                      v-model="item.warehouse"
                      :items="wholeSaleWarehouses"
                      outlined
                      dense
                      item-text="name"
                      return-object
                      hide-details="auto"
                      label="Warehouse"
                      :rules="requiredRule"
                      disabled
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="item.customerPoNumber"
                      label="Customer PO Number (optional)"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    v-if="item.type === 'RETURN' && false"
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="item.rmaNumber"
                      label="RMA Number"
                      hint="This will be auto generated. Only override if you know what you are doing. This has to be unique system wide."
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                      disabled
                    >
                    </v-text-field>
                  </v-col>

                  <v-col v-if="editMode" cols="12" md="6">
                    <v-currency-field
                      v-model="item.discountPercentage"
                      label="Discount"
                      autocomplete="off"
                      outlined
                      hide-details="auto"
                      suffix="%"
                      dense
                    ></v-currency-field>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-menu
                          v-model="menu1"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="deliveryStartDate"
                              clearable
                              label="Delivery Start"
                              append-icon="mdi-calendar"
                              readonly
                              outlined
                              dense
                              hide-details="auto"
                              autocomplete="off"
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="item.deliveryStartDate = null"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.deliveryStartDate"
                            :min="$moment().format('YYYY-MM-DD')"
                            no-title
                            scrollable
                            @change="menu1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-menu
                          v-model="menu2"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="deliveryEndDate"
                              clearable
                              label="Delivery End"
                              append-icon="mdi-calendar"
                              readonly
                              outlined
                              dense
                              hide-details="auto"
                              autocomplete="off"
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="item.deliveryEndDate = null"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.deliveryEndDate"
                            :min="
                              $moment(item.deliveryStartDate)
                                .add(1, 'days')
                                .format('YYYY-MM-DD')
                            "
                            no-title
                            scrollable
                            @change="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="item.notes"
                      label="Notes (optional)"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SalesOrderEditDialog',
  props: {
    editedItem: {
      type: Object,
      required: true,
      default: () => ({})
    },
    customers: {
      type: Object,
      default: () => ({ items: [] })
    },
    warehouses: {
      type: Object,
      default: () => ({ items: [], total: 0, page: 1 })
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    salesOrderTypes: ['PREBOOK', 'REORDER', 'RETURN'],
    item: null,
    menu1: false,
    menu2: false
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Sales Order' : 'New Sales Order'
    },
    deliveryStartDate() {
      return this.item.deliveryStartDate
        ? this.$moment(this.item.deliveryStartDate).format('LL')
        : ''
    },
    deliveryEndDate() {
      return this.item.deliveryEndDate
        ? this.$moment(this.item.deliveryEndDate).format('LL')
        : ''
    },
    wholeSaleWarehouses() {
      return (
        this._.filter(
          this.warehouses.items,
          item => item.type === 'WHOLESALE'
        ) || []
      )
    },
    authLevel() {
      return this.$store.getters.authLevel('sales_orders')
    }
  },
  watch: {
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.editedItem)
    }
  },
  methods: {
    close() {
      this.$refs.salesOrderForm.reset()
      this.$emit('close-sales-order-edit-dialog', false)
    },
    save() {
      if (this.$refs.salesOrderForm.validate()) {
        var data = {
          id: this.item.id,
          type: this.item.type,
          customerID: this.item.customer?.id,
          notes: this.item.notes,
          customerPoNumber: this.item.customerPoNumber,
          rmaNumber: this.item.rmaNumber,
          taxPercentage: this.item.taxPercentage,
          discountPercentage: this.item.discountPercentage || 0,
          warehouseID: this.item.warehouse?.id || null,
          deliveryStartDate:
            this.$moment(this.item.deliveryStartDate).utc() || null,
          deliveryEndDate: this.$moment(this.item.deliveryEndDate).utc() || null
        }

        if (!this.editMode) {
          delete data.id
          delete data.warehouseID
          delete data.discountPercentage
        }

        if (this.editMode) {
          delete data.type
          delete data.customerID
          delete data.taxPercentage
        }

        this.$emit('save-sales-order-edit-dialog', data)
      }
    }
  }
}
</script>

<style></style>
