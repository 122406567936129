<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header pt-0 px-1">
        <v-dialog v-model="editDialog" persistent max-width="600">
          <v-card>
            <v-card-title class="headline primary--text mb-3"
              >{{title}}</v-card-title
            >
            <v-card-text>
              <v-alert type="info" border="left" dense>
                Make sure the name is exactly as in Shopify
              </v-alert>
              <div v-if="errorMsg !== ''" class="pa-3 pb-0">
                <v-alert type="error" class="mb-0">
                  {{ errorMsg }}
                </v-alert>
              </div>
              <div>
                <v-form
                  ref="tagForm"
                  v-model="valid"
                  lazy-validation
                  @submit.prevent
                >
                  <v-row id="scroll-target" justify="center">
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.name"
                        autocomplete="off"
                        label="Name"
                        hide-details="auto"
                        outlined
                        :rules="requiredRule"
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-textarea
                        v-model="editedItem.description"
                        rows="3"
                        autocomplete="off"
                        label="Description"
                        placeholder="Description (optional)"
                        persistent-hint
                        hide-details="auto"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
            </div>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="grey darken-1" text rounded @click="close"
                >Cancel</v-btn
              >
              <v-btn color="primary" rounded small @click="addTag"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="removeDialog" persistent max-width="350">
          <v-card>
            <v-card-title class="headline error--text mb-3"
              >Remove Tag</v-card-title
            >
            <v-card-text>
              <p>
                This will delete tag: <strong>{{editedItem.name}}</strong>
              </p>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="grey darken-1" text rounded @click="close"
                >Cancel</v-btn
              >
              <v-btn color="error" text rounded @click="removeTag"
                >Remove</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-toolbar color="transparent" flat class="mb-3 b-radius">
          <v-row no-gutters>
            <v-col>
              <v-btn v-if="authLevel === 2" color="primary" rounded @click="openEditTagDialog()">
                  add tag
                </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0 pr-3">
        <div class="scroll-container pt-0">
          <v-row no-gutters justify="center">
            <v-col cols="12" md="6">
              <v-card flat class="mb-3">
                <v-data-table
                  :headers="headers"
                  :items="getTags"
                  :loading="$apollo.loading"
                  :items-per-page="-1"
                  hide-default-footer
                  :mobile-breakpoint="null"
                  class="plain-table grey--text text--darken-2"

                >
                  <template v-slot:item.controls="data">
                    <v-row justify="end">
                      <v-btn
                      @click="openEditTagDialog(data.item)"
                      color="info"
                      text
                      small
                      rounded
                      >edit</v-btn
                    >
                    <v-btn
                      @click="openRemoveTagDialog(data.item)"
                      color="grey lighten-1"
                      text
                      small
                      rounded
                      >remove</v-btn
                    >
                    </v-row>

                  </template>
                  <template v-slot:no-data>Nothing here yet...</template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ECOMM_TAGS from '@/graphql/EcommTags.gql'
import ECOMM_TAG_CREATE from '@/graphql/EcommTagCreate.gql'
import ECOMM_TAG_UPDATE from '@/graphql/EcommTagUpdate.gql'
import ECOMM_TAG_REMOVE from '@/graphql/EcommTagRemove.gql'

export default {
  name: 'EcommTagList',
  components: {},
  data: () => ({
    valid: true,
    editDialog: false,
    removeDialog: false,
    editMode: false,
    errorMsg: '',
    processing: false,
    defaultItem: {
      id: null,
      name: null,
      description: '',
    },
    editedItem: {},
    cloneEditedItem: {
      orderID: null,
      customerID: null,
      notes: null,
      customerPoNumber: null,
      deliveryStartDate: new Date(),
      deliveryEndDate: new Date()
    },
    tags:[],
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Tag' : 'New Tag'
    },
    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
          align: 'left',
          visible: false,
          sortable: false
        },
        {
          text: 'Description',
          align: 'left',
          value: 'description'
        },
        {
          text: '',
          value: 'controls',
          filterable: true,
          sortable: false
        },
      ]
    },
    authLevel() {
      return this.$store.getters.authLevel('ecomm_orders')
    }
  },
  apollo: {
    getTags: ECOMM_TAGS
  },
  watch: {},
  created() {},
  methods: {
    openEditTagDialog(item) {
      if(item && item.id) {
        this.editMode = true
        this.editedItem = this._.cloneDeep(item)
      } else {
        this.editMode = false
        this.editedItem = this.defaultItem
      }

      this.editDialog = true
    },
    openRemoveTagDialog(item) {
      this.editedItem = this._.cloneDeep(item)
      this.removeDialog = true
    },
    close() {
      this.editedItem = {}
      this.editDialog = false
      this.removeDialog = false
      this.editMode = false
      this.errorMsg = ''
      this.processing = false
    },
    removeTag() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: ECOMM_TAG_REMOVE,
          variables: {
            id: this.editedItem.id
          }
        })
        .then(() => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Tag Removed'
          })

          this.$apollo.queries.getTags.refetch()
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    addTag() {
      this.errorMsg = ''
      this.processing = true

      if (this.$refs.tagForm.validate()) {
        var data = {
          id: this.editedItem.id,
          name: this.editedItem.name,
          description: this.editedItem.description
        }

        if(this.editMode) {
          this.$apollo
            .mutate({
              mutation: ECOMM_TAG_UPDATE,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.close()
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Tag Updated'
              })

              this.$apollo.queries.getTags.refetch()
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong.'
              this.processing = false
            })
        } else {
          delete data.id

          this.$apollo
            .mutate({
              mutation: ECOMM_TAG_CREATE,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.close()
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Tag Created'
              })

              this.$apollo.queries.getTags.refetch()
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong.'
              this.processing = false
            })
        }
      }

    }
  }
}
</script>

<style lang="scss">
// .list-item {
//   // border: 1px solid transparent;
//   &:hover {
//     border-color: var(--v-primary-base);
//   }
// }

// .plain-table .v-input__slot {
//   background: transparent !important;
// }

// .plain-table.theme--light.v-data-table
//   tbody
//   tr:hover:not(.v-data-table__expanded__content) {
//   cursor: pointer !important;
// }
</style>
