<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header pt-0 px-1">
        <v-dialog v-model="dialog" persistent max-width="600">
          <v-card>
            <v-card-title class="headline primary--text mb-3">{{
              title
            }}</v-card-title>
            <v-card-text>
              <v-alert type="warning" border="left" dense>
                Changes may lead to orders being processed multiple times.
                Proceed with caution!
              </v-alert>
              <div v-if="errorMsg !== ''" class="my-3">
                <v-alert type="error" class="mb-0">
                  {{ errorMsg }}
                </v-alert>
              </div>
              <div>
                <v-form
                  ref="ecommOrderForm"
                  v-model="valid"
                  lazy-validation
                  @submit.prevent
                >
                  <v-row id="scroll-target" justify="center">
                    <div
                      v-if="
                        editMode === 'SEND_MULTIPLE' || editMode === 'SEND_ONE'
                      "
                      class="my-6"
                    >
                      This will send or resend the order to the warehouse for
                      fulfillment.
                    </div>
                    <div v-if="editMode === 'ON_HOLD'" class="my-6">
                      This will put the order on hold. You will need to process
                      this order manually later.
                    </div>
                    <v-col
                      cols="12"
                      v-if="
                        editMode !== 'SEND_MULTIPLE' &&
                          editMode !== 'SEND_ONE' &&
                          editMode !== 'STATE_CHANGE_SELECTED' &&
                          editMode !== 'ON_HOLD'
                      "
                    >
                      <v-select
                        v-model="editedItem.oldStatus"
                        :items="ecommStates"
                        :rules="requiredSelectRule"
                        label="Current Status"
                        placeholder="-- Select --"
                        persistent-placeholder
                        hide-details
                        outlined
                        flat
                        dense
                        background-color="#fff"
                      ></v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      v-if="
                        editMode !== 'SEND_MULTIPLE' &&
                          editMode !== 'SEND_ONE' &&
                          editMode !== 'STATE_CHANGE_SELECTED' &&
                          editMode !== 'ON_HOLD'
                      "
                    >
                      <v-select
                        v-model="editedItem.tag"
                        :items="tags"
                        :rules="requiredSelectRule"
                        :disabled="!editedItem.oldStatus"
                        label="Tag (optional)"
                        placeholder="-- Select (optional) --"
                        persistent-placeholder
                        hide-details
                        outlined
                        flat
                        dense
                        clearable
                        background-color="#fff"
                      ></v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      v-if="
                        editMode !== 'SEND_MULTIPLE' &&
                          editMode !== 'SEND_BULK' &&
                          editMode !== 'SEND_ONE' &&
                          editMode !== 'ON_HOLD'
                      "
                    >
                      <v-select
                        v-model="editedItem.newStatus"
                        :items="ecommStates"
                        :rules="requiredSelectRule"
                        label="New Status"
                        placeholder="-- Select --"
                        persistent-placeholder
                        hide-details
                        outlined
                        flat
                        dense
                        background-color="#fff"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="grey darken-1" text rounded @click="close"
                >Cancel</v-btn
              >
              <v-btn color="primary" rounded small @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-toolbar color="transparent" flat class="mb-3 b-radius">
          <v-row no-gutters>
            <v-col md="3" lg="2">
              <v-select
                v-model="ecommOrderState"
                :disabled="disableFilters"
                :items="ecommStates"
                label="Status"
                placeholder="-- Select --"
                persistent-placeholder
                hide-details
                clearable
                outlined
                flat
                dense
                background-color="#fff"
              ></v-select>
            </v-col>
            <v-col class="px-3" md="3" lg="2">
              <v-select
                v-model="tag"
                :disabled="disableFilters"
                :items="tags"
                label="Tag"
                placeholder="-- Select --"
                persistent-placeholder
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
              ></v-select>
            </v-col>

            <v-col class="px-3">
              <v-row no-gutters justify="end">
                <div class="mr-6 pt-1">{{ itemCount }} Items</div>
                <v-btn
                  v-if="authLevel === 2 && (!selected || selected.length === 0)"
                  color="primary"
                  rounded
                  class="mr-3"
                  @click="openDialog('STATE_CHANGE_BULK')"
                >
                  change status
                </v-btn>
                <v-btn
                  v-if="authLevel === 2 && (!selected || selected.length === 0)"
                  color="info"
                  rounded
                  @click="openDialog('SEND_BULK')"
                >
                  send to warehouse
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0 pr-3">
        <div class="scroll-container pt-0">
          <v-card flat class="mb-3">
            <v-card-title>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    label="Search Order Number..."
                    single-line
                    hide-details
                    clearable
                    outlined
                    flat
                    dense
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-row justify="end">
                    <div class="pt-6 pr-3">
                      <v-btn
                      v-if="authLevel === 2 && selected && selected.length > 0"
                      color="primary"
                      rounded
                      small
                      class="mr-3"
                      @click="openDialog('STATE_CHANGE_SELECTED')"
                    >
                      change status
                    </v-btn>
                    <v-btn
                      v-if="
                        authLevel === 2 &&
                          selected &&
                          selected.length > 0 &&
                          ecommOrderState !== 'FRAUD_CHECK'
                      "
                      color="info"
                      rounded
                      small
                      @click="openDialog('SEND_MULTIPLE')"
                    >
                      send to warehouse
                    </v-btn>
                    </div>

                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="orders.items"
              show-select
              :search="search"
              :options.sync="options"
              :loading="$apollo.loading"
              :footer-props="{ 'items-per-page-options': [15, 30, 50, 100] }"
              :server-items-length="itemCount"
              :mobile-breakpoint="null"
              class="plain-table grey--text text--darken-2"
              :hide-default-footer="orders.total < options.itemsPerPage + 1"
              :hide-default-header="orders.itemCount === 0"
            >
              <template v-slot:item.createdAt="{ item }">
                <div class="text-truncate">
                  {{ item.createdAt | moment('LLL') }}
                </div>
              </template>
              <template v-slot:item.tags="{ item }">
                <div v-if="item.tags.length > 0 && item.tags[0]">
                  <v-chip
                    class="mx-1"
                    x-small
                    v-for="(tag, idx) in item.tags"
                    :key="idx"
                    >{{ tag }}</v-chip
                  >
                </div>
              </template>
              <template v-slot:item.billingAddress="{ item }">
                <div
                  v-if="item.billingAddress && item.billingAddress.firstName"
                  class="text-truncate"
                >
                  {{
                    item.billingAddress.firstName +
                      ' ' +
                      item.billingAddress.lastName
                  }}
                </div>
              </template>
              <template v-slot:item.shippingAddress="{ item }">
                <div v-if="item.shippingAddress && item.shippingAddress.firstName" class="text-truncate">
                  {{
                    item.shippingAddress.firstName +
                      ' ' +
                      item.shippingAddress.lastName
                  }}
                </div>
                <div v-if="item.shippingAddress" class="text-truncate">
                  <span>{{ item.shippingAddress.address1 }}</span>
                  <span v-if="item.shippingAddress.address2"
                    >, {{ item.shippingAddress.address2 }}</span
                  >
                  <span v-if="item.shippingAddress.zip"
                    >, {{ item.shippingAddress.zip }}</span
                  >
                  <span v-if="item.shippingAddress.city"
                    >, {{ item.shippingAddress.city }}</span
                  >
                  <span v-if="item.shippingAddress.country"
                    >, {{ item.shippingAddress.country }}</span
                  >
                </div>
              </template>
              <template v-slot:item.controls="data">
                <v-btn
                  v-if="
                    data.item.status != 'FRAUD_CHECK' &&
                      (!selected || selected.length === 0)
                  "
                  @click="openDialog('SEND_ONE', data.item)"
                  color="info"
                  outlined
                  small
                  rounded
                  >send to warehouse</v-btn
                >
                <v-btn
                  v-else-if="
                    data.item.status != 'REQUEST_FULFILLMENT' &&
                      data.item.status != 'FULFILLED' &&
                      data.item.status != 'CANCELED' &&
                      (!selected || selected.length === 0)
                  "
                  @click="openDialog('ON_HOLD', data.item)"
                  color="info"
                  outlined
                  small
                  rounded
                  >put on hold</v-btn
                >
              </template>
              <template v-slot:no-data>Nothing here yet...</template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ECOMM_ORDER_LIST from '@/graphql/EcommOrders.gql'
import ECOMM_TAGS from '@/graphql/EcommTags.gql'
import ECOMM_ORDER_CHANGE_STATE from '@/graphql/EcommOrderChangeState.gql'
import ECOMM_ORDER_CHANGE_STATE_BY_TAG from '@/graphql/EcommOrderChangeStateByTag.gql'

export default {
  name: 'EcommOrderList',
  components: {},
  data: () => ({
    dialog: false,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    editMode: null,
    errorMsg: '',
    processing: false,
    search: null,
    customerID: null,
    selected: [],
    searchCustomers: '',
    searchLineSheet: '',
    itemCount: 0,
    options: { page: 1, itemsPerPage: 15 },
    orders: { items: [], total: 0, page: 1 },
    defaultItem: {
      ids: null,
      tag: null,
      orderIds: [],
      oldStatus: null,
      newStatus: null
    },
    editedItem: {},
    ecommOrderState: null,
    ecommStates: [
      // 'ALL',
      'SUSPENDED',
      'REQUEST_FULFILLMENT',
      'MANUAL_FULFILLMENT',
      'ON_HOLD',
      'FRAUD_CHECK',
      'FULFILLED',
      'CANCELED'
    ],
    tag: null,
    defaultTags: [
      'Riskified::declined',
      'Riskified::approved',
      'seeding',
      'CodePickupPoint'
    ],
    tags: [],
    disableFilters: false,
    previousState: null
  }),
  computed: {
    title() {
      return ''
    },
    headers() {
      return [
        {
          text: 'Order Number',
          value: 'name',
          align: 'left',
          visible: false,
          sortable: false
        },
        {
          text: 'Order Date',
          align: 'left',
          value: 'createdAt',
          filterable: false,
          sortable: false
        },
        {
          text: 'Shopify Id',
          align: 'left',
          value: 'id'
        },
        {
          text: 'Tags',
          align: 'left',
          value: 'tags'
        },
        {
          text: 'Status',
          value: 'status',
          visible: false,
          sortable: false,
          align: 'left'
        },
        {
          text: 'Customer',
          align: 'left',
          sortable: false,
          visible: false,
          value: 'billingAddress'
        },
        {
          text: 'Schipping To',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'shippingAddress'
        },
        {
          text: '',
          value: 'controls',
          filterable: true,
          sortable: false
        }
      ]
    },
    authLevel() {
      return this.$store.getters.authLevel('ecomm_orders')
    }
  },
  apollo: {
    getEcommOrders: {
      query: ECOMM_ORDER_LIST,
      variables() {
        return {
          filters: {
            search: this.search,
            tag: this.tag,
            status: this.ecommOrderState,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        if (result.data) {
          this.orders = result.data.getEcommOrders
          this.itemCount = result.data.getEcommOrders.itemCount

          this.options.page = result.data.getEcommOrders.page
          this.options.itemsPerPage = result.data.getEcommOrders.pageSize
        }
      },
      skip() {
        return !this.ecommOrderState
      }
    },
    getTags: {
      query: ECOMM_TAGS,
      result(result) {
        if (result.data.getTags && result.data.getTags.length > 0) {
          result.data.getTags.forEach(item => this.tags.push(item.name))
        }

        this.tags.push(...this.defaultTags)
      }
    }
  },
  watch: {
    search(val) {
      this.selected = []

      if (val) {
        this.options.page = 1
        this.ecommOrderState = 'ALL'
        this.disableFilters = true
      } else {
        this.disableFilters = false

        if (this.ecommOrderState && this.ecommOrderState !== 'ALL') {
          this.ecommOrderState = this.previousState
          this.previousState = null
        } else {
          this.ecommOrderState = null
        }
      }
    },
    ecommOrderState(val) {
      this.options.page = 1
      this.selected = []

      if (!val && !this.tag) {
        this.orders = { items: [] }
        this.options = { page: 1, itemsPerPage: 15 }
      }
    },
    tag(val) {
      this.options.page = 1
      this.selected = []

      if (!val && !this.ecommOrderState) {
        this.orders = { items: [] }
        this.options = { page: 1, itemsPerPage: 15 }
      }
    }
  },
  created() {},
  methods: {
    getIds() {
      var ids = []

      if (this.selected && this.selected.length > 0) {
        this.selected.forEach(item => ids.push(item.id))
      }

      return ids
    },
    openDialog(method, item) {
      this.editMode = method

      switch (method) {
        case 'SEND_MULTIPLE':
          if (this.selected && this.selected.length > 0) {
            var ids = this.getIds()

            this.editedItem = {
              tag: null,
              orderIds: ids,
              oldStatus: 'ON_HOLD',
              newStatus: 'MANUAL_FULFILLMENT'
            }

            this.dialog = true
          }
          break
        case 'SEND_BULK':
          this.editedItem = {
            tag: null,
            orderIds: null,
            oldStatus: 'ON_HOLD',
            newStatus: 'MANUAL_FULFILLMENT'
          }

          this.dialog = true
          break
        case 'SEND_ONE':
          var ids = []
          ids.push(item.id)

          if (item && item.id) {
            this.editedItem = {
              tag: null,
              orderIds: ids,
              oldStatus: item.status,
              newStatus: 'MANUAL_FULFILLMENT'
            }

            this.dialog = true
          }
          break
        case 'STATE_CHANGE_ONE':
          if (item && item.id) {
            let ids = []
            ids.push(item.id)

            this.editedItem = {
              tag: null,
              orderIds: ids,
              oldStatus: null,
              newStatus: null
            }

            this.dialog = true
          }
          break
        case 'ON_HOLD':
          var ids = []
          ids.push(item.id)

          if (item && item.id) {
            this.editedItem = {
              tag: null,
              orderIds: ids,
              oldStatus: item.status,
              newStatus: 'ON_HOLD'
            }

            this.dialog = true
          }
          break
        case 'STATE_CHANGE_SELECTED':
          if (this.selected && this.selected.length > 0) {
            let ids = this.getIds()

            this.editedItem = {
              tag: null,
              orderIds: ids,
              oldStatus: this.ecommOrderState,
              newStatus: null
            }

            this.dialog = true
          }
          break
        case 'STATE_CHANGE_BULK':
          this.editedItem = {
            tag: null,
            orderIds: null,
            oldStatus: null,
            newStatus: null
          }

          this.dialog = true
          break
        default:
          break
      }
    },
    close() {
      this.editedItem = {}
      this.dialog = false
      this.editMode = null
      this.errorMsg = ''
      this.processing = false
    },
    save() {
      this.errorMsg = ''
      this.processing = true

      if (this.$refs.ecommOrderForm.validate()) {
        var data = {
          orderIds: this.editedItem.orderIds,
          tag: this.editedItem.tag,
          oldStatus: this.editedItem.oldStatus,
          newStatus: this.editedItem.newStatus
        }

        if (data.tag) {
          this.$apollo
            .mutate({
              mutation: ECOMM_ORDER_CHANGE_STATE_BY_TAG,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.close()
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Processed Successfully'
              })

              this.$apollo.queries.getEcommOrders.refetch()
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong.'
              this.processing = false
            })
        } else {
          this.$apollo
            .mutate({
              mutation: ECOMM_ORDER_CHANGE_STATE,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.close()
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Processed Successfully'
              })

              this.$apollo.queries.getEcommOrders.refetch()
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong.'
              this.processing = false
            })
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
