<template>
  <v-row v-if="salesOrder && salesOrder.id">
    <v-col cols="12" md="6" class="py-0">
      <v-card flat class="mb-3">
        <v-card-title class="mb-3 grey--text"
          ><div v-if="salesOrder.type === 'RETURN'">
            RMA Number:
            <span class="primary--text text-h6">{{
              salesOrder.rmaNumber
            }}</span>
          </div>
          <div v-else>
            Order Number:
            <span class="primary--text text-h6">
              {{ salesOrder.orderNumber }}</span
            >
          </div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              !editMode &&
                salesOrder &&
                salesOrder.state &&
                (salesOrder.state === 'DRAFT' ||
                  salesOrder.state === 'SUBMITTED') &&
                ((authLevel && authLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            rounded
            outlined
            small
            color="grey"
            :disabled="processing"
            @click="editSalesOrder"
          >
            edit
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="mb-3">
              <!-- <div class="grey--text text-subtitle-1 mb-3">
                <div v-if="salesOrder.type === 'RETURN'">
                  RMA Number:
                  <span class="primary--text text-h6">{{
                    salesOrder.rmaNumber
                  }}</span>
                </div>
                <div v-else>
                  Order Number:
                  <span class="primary--text text-h6">
                    {{ salesOrder.orderNumber }}</span
                  >
                </div>
              </div> -->
              <span class="mr-3">
                <v-chip
                  v-if="salesOrder.type === 'PREBOOK'"
                  x-small
                  color="success lighten-3 font-weight-medium"
                  text-color="success"
                  >{{ salesOrder.type }}</v-chip
                >
                <v-chip
                  v-if="salesOrder.type === 'REORDER'"
                  x-small
                  color="info lighten-2 font-weight-medium"
                  text-color="info darken-1"
                  >{{ salesOrder.type }}</v-chip
                >
                <v-chip
                  v-if="salesOrder.type === 'RETURN'"
                  x-small
                  color="warning lighten-1 font-weight-medium"
                  text-color="warning darken-3"
                  >{{ salesOrder.type }}</v-chip
                >
              </span>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Store</label>
                </v-col>
                <v-col cols="12">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    salesOrder.customer.storeName
                  }}</span>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Company</label>
                </v-col>
                <v-col cols="12">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    salesOrder.customer.companyName
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Agent</label>
                </v-col>
                <v-col cols="12">
                  <span
                    v-if="salesOrder.agent"
                    class="font-weight-medium grey--text text--darken-2"
                    >{{ salesOrder.agent.name }}</span
                  >
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Created By</label>
                </v-col>
                <v-col cols="12">
                  <span
                    v-if="salesOrder.createdBy"
                    class="font-weight-medium grey--text text--darken-2"
                    >{{
                      salesOrder.createdBy.firstName +
                        ' ' +
                        salesOrder.createdBy.lastName
                    }}</span
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1"
                    >Order Reference / Customer PO</label
                  >
                </v-col>
                <v-col cols="12">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    salesOrder.customerPoNumber
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1"
                    >Delivery Start</label
                  >
                </v-col>
                <v-col cols="12">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    salesOrder.deliveryStartDate | moment('DD MMM YY')
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Delivery End</label>
                </v-col>
                <v-col cols="12">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    salesOrder.deliveryEndDate | moment('DD MMM YY')
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Warehouse</label>
                </v-col>
                <v-col cols="12">
                  <div class="text-truncate">
                    <span
                      v-if="salesOrder.warehouse"
                      class="font-weight-medium grey--text text--darken-2"
                      >{{ salesOrder.warehouse.name }}</span
                    ><span v-if="salesOrder.warehouse.provider" class="pl-3"
                      >({{ salesOrder.warehouse.provider.name }})</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Notes</label>
                </v-col>
                <v-col cols="12">
                  <div>
                    {{salesOrder.notes}}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="6" class="py-0">
      <v-row>
        <v-col cols="12" class="py-0">
          <v-card flat class="mb-3">
            <v-card-title class="mb-3"
              ><span>Billing Address</span><v-spacer></v-spacer>
              <v-btn
                v-if="
                  !editMode &&
                    salesOrder &&
                    salesOrder.state &&
                    (salesOrder.state === 'DRAFT' ||
                      salesOrder.state === 'SUBMITTED') &&
                    ((authLevel && authLevel === 2) ||
                      (currentUser &&
                        (currentUser.isSuperAdmin ||
                          currentUser.userRole === 'director' ||
                          currentUser.userRole === 'seniormanager' ||
                          currentUser.userRole === 'projectmanager')))
                "
                rounded
                outlined
                small
                color="grey"
                :disabled="processing"
                @click="editAddress('BILLING')"
              >
                <span
                  v-if="
                    salesOrder.billingAddress && salesOrder.billingAddress.id
                  "
                  >edit</span
                >
                <span v-else>add</span>
              </v-btn></v-card-title
            >
            <v-card-text
              v-if="salesOrder.billingAddress && salesOrder.billingAddress.id"
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Street</label>
                    </v-col>
                    <v-col cols="12">
                      <div class="font-weight-medium grey--text text--darken-2">
                        {{ salesOrder.billingAddress.line1 }}
                      </div>
                      <div class="font-weight-medium grey--text text--darken-2">
                        {{ salesOrder.billingAddress.line2 }}
                      </div>
                      <div class="font-weight-medium grey--text text--darken-2">
                        {{ salesOrder.billingAddress.line3 }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Postcode</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ salesOrder.billingAddress.postCode }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">City</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ salesOrder.billingAddress.city }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Country</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        v-if="
                          salesOrder.billingAddress &&
                            salesOrder.billingAddress.country
                        "
                        class="font-weight-medium grey--text text--darken-2"
                        >{{
                          getFullCountryName(salesOrder.billingAddress.country)
                        }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Email</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ salesOrder.billingAddress.email }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Phone</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ salesOrder.billingAddress.phone }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="py-0">
          <v-card flat class="mb-3">
            <v-card-title class="mb-3"
              ><span>Shipping Address</span><v-spacer></v-spacer>
              <v-btn
                v-if="
                  !editMode &&
                    salesOrder &&
                    salesOrder.state &&
                    (salesOrder.state === 'DRAFT' ||
                      salesOrder.state === 'SUBMITTED') &&
                    ((authLevel && authLevel === 2) ||
                      (currentUser &&
                        (currentUser.isSuperAdmin ||
                          currentUser.userRole === 'director' ||
                          currentUser.userRole === 'seniormanager' ||
                          currentUser.userRole === 'projectmanager')))
                "
                rounded
                outlined
                small
                color="grey"
                :disabled="processing"
                @click="editAddress('SHIPPING')"
              >
                <span
                  v-if="
                    salesOrder.shippingAddress && salesOrder.shippingAddress.id
                  "
                  >edit</span
                >
                <span v-else>add</span>
              </v-btn></v-card-title
            >
            <v-card-text
              v-if="salesOrder.shippingAddress && salesOrder.shippingAddress.id"
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Street</label>
                    </v-col>
                    <v-col cols="12">
                      <div class="font-weight-medium grey--text text--darken-2">
                        {{ salesOrder.shippingAddress.line1 }}
                      </div>
                      <div class="font-weight-medium grey--text text--darken-2">
                        {{ salesOrder.shippingAddress.line2 }}
                      </div>
                      <div class="font-weight-medium grey--text text--darken-2">
                        {{ salesOrder.shippingAddress.line3 }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Postcode</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ salesOrder.shippingAddress.postCode }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">City</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ salesOrder.shippingAddress.city }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Country</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        v-if="
                          salesOrder.shippingAddress &&
                            salesOrder.shippingAddress.country
                        "
                        class="font-weight-medium grey--text text--darken-2"
                        >{{
                          getFullCountryName(salesOrder.shippingAddress.country)
                        }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Email</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ salesOrder.shippingAddress.email }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Phone</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ salesOrder.shippingAddress.phone }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { getName } from 'country-list'
import SalesOrderEditDialog from '@/modules/sales/components/core/SalesOrderEditDialog.vue'
import AddressEditDialog from '@/modules/sales/components/core/AddressEditDialog.vue'
import ADDRESS_UPDATE from '@/graphql/SalesCustomerAddressUpdate.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'
import SALES_ORDER_UPDATE from '@/graphql/SalesOrderUpdate.gql'

export default {
  name: 'SalesOrderDetail',
  components: { SalesOrderEditDialog, AddressEditDialog },
  props: {
    salesOrder: {
      type: Object,
      default: () => ({})
    },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 }
  },
  apollo: {
    warehouses: {
      query: WAREHOUSE_LIST,
      variables() {
        return {
          filters: {
            search: null,
            page: 1,
            pageSize: 1000
          }
        }
      }
    }
  },
  data: () => ({
    dialog: false,
    editMode: false,
    processing: false,
    addressDialog: false,
    errorMsg: '',
    editedItem: {
      id: null,
      customerID: null,
      notes: null,
      customerPoNumber: null,
      rmaNumber: null,
      type: 'REORDER',
      deliveryStartDate: null,
      deliveryEndDate: null
    },
    addressDefaultItem: {
      id: null,
      customerID: null,
      agentID: null,
      line1: null,
      line2: null,
      line3: null,
      postCode: null,
      city: null,
      country: null,
      addressType: null,
      email: null,
      phone: null
    },
    addressEditedItem: {
      id: null,
      customerID: null,
      agentID: null,
      line1: null,
      line2: null,
      line3: null,
      postCode: null,
      city: null,
      country: null,
      addressType: null,
      email: null,
      phone: null
    }
  }),
  methods: {
    getFullCountryName(code) {
      return getName(code)
    },
    editAddress(addressType) {
      if (addressType === 'BILLING') {
        this.addressEditedItem = this._.cloneDeep(
          this.salesOrder.billingAddress
        )
        this.editMode = true
      }

      if (addressType === 'SHIPPING') {
        this.addressEditedItem = this._.cloneDeep(
          this.salesOrder.shippingAddress
        )
        this.editMode = true
      }

      this.addressDialog = true
    },
    editSalesOrder() {
      this.editedItem = this._.cloneDeep(this.salesOrder)
      this.editMode = true
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.addressDialog = false
      this.editedItem = this._.cloneDeep({})
      this.addressEditedItem = this._.cloneDeep(this.addressDefaultItem)
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    saveAddress(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: ADDRESS_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.$emit('refresh-sales-order')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Address Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.$emit('refresh-sales-order')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Sales Order Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
